import { BaseEntity } from "../entities/base_entity";

export interface IMessageTemplate extends BaseEntity<IMessageTemplate> {
	text: string;
	kind: MessageTemplateKind;
	startDateTime: Date;
	endDateTime: Date;
	shouldSendInWeekend: boolean;
}

export enum MessageTemplateKind {
	HSM = "HSM",
	STANDARD = "STANDARD",
	BOT = "BOT",
	RECEPTIVE = "RECEPTIVE",
	FEEDBACK = "FEEDBACK",
}

export const MessageTemplateKindTranslated: Record<MessageTemplateKind, string> = {
	[MessageTemplateKind.HSM]: "Template",
	[MessageTemplateKind.STANDARD]: "Mensagem rápida",
	[MessageTemplateKind.BOT]: "Resposta automática",
	[MessageTemplateKind.RECEPTIVE]: "Receptiva",
	[MessageTemplateKind.FEEDBACK]: "Pesquisa de satisfação",
};

export function translateMessageTemplateKind(status?: MessageTemplateKind): string {
	if (!status) return "";
	return MessageTemplateKindTranslated[status];
}

export function translateMessageTemplateBoolean(status: boolean): string {
	return !!status ? "Sim" : "Não";
}
