import { ListOptionsDto } from "@/interfaces/list_options.dto";
import { corporateClientModel } from "./corporate_client.model";
import { CrudModel } from "./_crud";
import { crudGet, crudRead } from "./_request";

export class messageTemplateModel extends CrudModel {
	constructor() {
		super("admin/messageTemplate", [], [{ fieldKey: "corporateClient", model: corporateClientModel }]);
	}

	async getMessageTemplateList(listDto: Pick<ListOptionsDto, "searchParam" | "limit" | "page">) {
		return this.get("", listDto);
	}

	override async read(id: string) {
		const queryParameters = {
			join: await this.getJoinableFields(),
		};

		return crudRead(this.endpoint, id, queryParameters);
	}
}

export default new messageTemplateModel();
