






































import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import EntityForm from "@/views/entity/EntityForm.vue";
import messageTemplateModel from "@/api/message_template.model";
import { translateMessageTemplateBoolean, translateMessageTemplateKind } from "@/interfaces/message_template";
import corporateClientModel from "@/api/corporate_client.model";
import subHours from "date-fns/subHours";

@Component({
	components: { EntityCrud, EntityForm },
})
export default class MessageTemplates extends Vue {
	model: any = messageTemplateModel;
	corporateClientModel = corporateClientModel;
	title = "Templates";
	isReadOnly: boolean = true;
	defaultEntity = {};
	// kind = "editionForm";
	kind = "messageTemplateTable";
	withEntityHeader = true;
	cleanFormKeys = {};
	isLoading = false;
	shouldShowHeaderName = false;
	shouldShowExportButton = false;

	shouldGoToPreviousPageAfterRemove = false;
	filter = { kind: { $in: ["HSM", "STANDARD"] } };
	onRemoveEntity() {
		this.$root.$emit("refreshTable");
	}

	tableColumns = [
		{
			key: "name",
			name: "Nome",
		},
		{
			key: "text",
			name: "Mensagem",
		},
		{
			key: "corporateClient.name",
			name: "Cliente corporativo",
			hasSelect: true,
			options: [{}],
		},
		{
			key: "kind",
			kind: "select",
			isReadOnly: false,
			required: true,
		},
	];

	formColumns = [
		{
			key: "name",
			name: "Nome",
			isReadOnly: false,
			required: true,
		},
		{
			key: "text",
			name: "Mensagem",
			required: true,
			isReadOnly: false,
			shouldShowHelp: true,
			helpText:
				"Para garantir a consistência da ferramenta, não altere as propriedades envolvidas em chaves duplas (Exemplo: {{nome do usuário}})",
		},
		{
			key: "kind",
			kind: "select",
			isReadOnly: false,
			required: true,
			onTransform: translateMessageTemplateKind,
		},
		{
			key: "contentSid",
			name: "Content SID",
			required: false,
			isReadOnly: false,
		},
		{
			key: "shouldSendInWeekend",
			name: "Deve enviar em finais de semana?",
			required: false,
			kind: "multiselect",
			inputKind: "selectButtons",
			isReadOnly: true,
			options: [
				{ name: "Sim", value: true },
				{ name: "Não", value: false },
			],
			onTransform: translateMessageTemplateBoolean,
		},
		{
			key: "startDateTime",
			name: "Início",
			required: false,
			isReadOnly: false,
			kind: "time",
			onTransform: this.formatToHour,
		},
		{
			key: "endDateTime",
			name: "Fim",
			required: false,
			isReadOnly: false,
			kind: "time",
			onTransform: this.formatToHour,
		},
		{
			key: "expirationTime",
			required: false,
			isReadOnly: false,
			kind: "number",
		},
		{
			key: "corporateClient.id",
			name: "Nome do cliente corporativo",
			kind: "select",
			options: [{}],
		},
	];

	get currentId() {
		return this.$route.params.id;
	}

	@Watch("currentId")
	changeReadOnly() {
		if (!this.currentId) {
			this.isReadOnly = true;
		}
		if (this.currentId === "novo") {
			this.defaultEntity = {};
		}
	}

	onBack() {
		this.$router.back();
	}

	async mounted() {
		await this.getRelationFields();
	}

	async getRelationFields() {
		await this.loadCorporateClients();

		const foundColumn = this.tableColumns.find(tableColumn => tableColumn.key === "corporateClient.name");
		if (foundColumn) {
			const corporateClients = await corporateClientModel.get("");
			foundColumn.options = corporateClients.map((corporateClient: any) => {
				return { name: corporateClient.name, value: corporateClient.id };
			});
		}
	}

	async loadCorporateClients() {
		const corporateClients = await corporateClientModel.search({});
		if (!this.formColumns.filter((field: any) => field.key === "corporateClient.id").length) {
			this.formColumns.unshift({
				key: "corporateClient.id",
				name: "Nome do cliente corporativo",
				kind: "select",
				options: corporateClients.map((corporateClient: any) => {
					return {
						name: corporateClient.name,
						value: corporateClient.id,
					};
				}),
			});
		} else {
			this.formColumns.find((field: any) => field.key === "corporateClient.id")!.options = corporateClients.map(
				(corporateClient: any) => {
					return {
						name: corporateClient.name,
						value: corporateClient.id,
					};
				},
			);
		}
	}

	formatToHour(datetime: Date) {
		if (datetime) {
			let formattedDate: Date | undefined = undefined;
			if (typeof datetime === "string" && String(datetime).length === 5) {
				formattedDate = new Date();
				const [hours, minutes] = String(datetime).split(":");
				formattedDate.setHours(Number(hours));
				formattedDate.setMinutes(Number(minutes));
			}
			const date = formattedDate ?? new Date(datetime);
			return new Intl.DateTimeFormat("pt-BR", {
				timeZone: "UTC",
				hour: "numeric",
				minute: "numeric",
			}).format(subHours(new Date(date), 3));
		}
		return "";
	}
}
